<section class="login">
    <div class="container">
        <div class="layout-container">
            <div class="layout">
                <div class="login-block">
                    <div class="login-logo"><img class="image-cls" mat-card-image src="{{ siteSettings.siteLogo }}" alt=""></div>
                    <div class="logo-moto">Forgot Password</div>
                    <form [formGroup]="form" (ngSubmit)="formSubmitHandler()"> 
                        <div class="login-form" fxLayout="column" fxLayoutGap="15px">
                            <div fxLayout="column" fxLayoutGap="0px" fxLayoutAlign="start">
                                <label class="form-label">Email ID:</label>
                                <mat-form-field appearance="outline">
                                    <input 
                                    matInput 
                                    formControlName="email" 
                                    required 
                                    placeholder="info@example.com">
                                    <mat-icon matSuffix>person_outline</mat-icon>
                                </mat-form-field>
                            </div>
                            <div class="forgot-lnk" fxLayoutAlign="end">
                                Do have an account? <a class="hyperlink-clr crs-ptr mrg-leftval" routerLink="/login"> Login</a>
                            </div>
                            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                                <button 
                                mat-button 
                                type="submit"
                                [disabled]="form.invalid || isLoading"
                                class="mat-button button-cls login-btn" style="width: 100%;">Submit</button>
                            </div>
                        </div>
                    </form>
                    <div class="login-copyright">
                        <p>Copyright {{year}} - {{ siteSettings.siteName }} Inc. All Rights Reserved</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>